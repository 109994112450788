import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

for (var i = 1; i < 7; i++) {
    new Swiper(`.swiper-${i}`, {
        modules: [Navigation, Pagination],
        loop: true,
        navigation: {
            nextEl: `.next-${i}`,
            prevEl: `.prev-${i}`,
        },
    });
}

const presentationButton = document.querySelector('.heading_pres');
presentationButton.addEventListener('click', () => {
    gsap.to(window, { duration: 0.75, ease: 'power2.out', scrollTo: { y: document.querySelector('.presentation'), offsetY: 70 } });
});

const experiencesButton = document.querySelector('.heading_exp');
experiencesButton.addEventListener('click', () => {
    gsap.to(window, { duration: 0.75, ease: 'power2.out', scrollTo: { y: document.querySelector('.projects'), offsetY: 70 } });
});

const contactButton = document.querySelector('.heading_contact');
contactButton.addEventListener('click', () => {
    gsap.to(window, { duration: 0.75, ease: 'power2.out', scrollTo: { y: document.querySelector('.contact'), offsetY: 70 } });
});

ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause"
});

gsap.to(".presentation__line span", {
    scrollTrigger: ".presentation__title",
    duration: 1.5, 
    width: "100%", 
    ease: "power4.out"
});

gsap.to(".projects__line span", {
    scrollTrigger: ".projects__title",
    duration: 1.5, 
    width: "100%",
    ease: "power4.out"
});

gsap.to(".contact__line span", {
    scrollTrigger: ".contact__title",
    duration: 1.5, 
    width: "100%", 
    ease: "power4.out"
});

gsap.to(".parallax-bg", {
    scrollTrigger: {
      scrub: true
    }, 
    y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
    ease: "none"
});

const body = document.querySelector('body');
const projectFilter = document.querySelector('.single_filter');

for (var i = 1; i < 7; i++) {
    const button = document.querySelector(`.proj-button-${i}`);
    const project = document.querySelector(`.project-${i}`);
    button.addEventListener('click', () => {
        handleClickOnButton(project);
    });
}

const projectCloseButton = document.querySelectorAll('.single_project__close');
projectCloseButton.forEach(el => {
    el.addEventListener('click', handleClickOnClose);
});

function handleClickOnButton(project) {
    // console.log('click on button');
    project.style.display = 'flex';
    project.classList.add('active');
    projectFilter.style.display = 'block';
    body.style.margin = '0';
    body.style.height = '100%';
    body.style.overflow = 'hidden';
}

function handleClickOnClose() {
    const project = document.querySelector('.active');
    // console.log('click on close', project);
    project.style.display = 'none';
    project.classList.remove('active');
    projectFilter.style.display = 'none';
    body.style.height = 'auto';
    body.style.overflow = 'unset';
}